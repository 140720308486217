import { ToastContext } from "@curaleaf-international/components";
import Card from "@mui/material/Card";
import { useContext } from "react";
import { useLocation } from "wouter";

import SystemForm, { ValidatedType } from "src/components/SystemForm";
import { AccessType, ChangeType, IntegrationType, UARType } from "src/models";
import { useCreateSystemMutation } from "src/queries";

const CreateSystem = () => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { mutateAsync: create } = useCreateSystemMutation();

  const defaultValues = {
    accessType: AccessType.MANUAL,
    changeType: ChangeType.MANUAL,
    description: "",
    integration: IntegrationType.NONE,
    integrationArgs: "",
    multirole: false,
    name: "",
    requestableRoles: "",
    uarType: UARType.MANUAL,
  };

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({
        ...data,
        integrationArgs:
          data.integrationArgs === null
            ? undefined
            : JSON.parse(data.integrationArgs),
        requestableRoles:
          data.requestableRoles?.split(",").map((role) => role.trim()) ?? [],
      });
      addToast("System created", "success");
      setLocation("/systems/");
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <Card>
      <SystemForm
        back="/systems/"
        defaultValues={defaultValues}
        label="Create System"
        onSubmit={onSubmit}
      />
    </Card>
  );
};

export default CreateSystem;
