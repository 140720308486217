import {
  SubmitButton,
  TextField,
  ToastContext,
  FormLinks,
  Title,
  cz,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import MultiCheckbox from "src/components/MultiCheckbox";
import { StaffRole } from "src/models";
import {
  useStaffQuery,
  useSystemsQuery,
  useUpdateStaffRolesMutation,
} from "src/queries";

interface IRoles {
  [key: string]: boolean;
}

interface IProps {
  staffId: number;
}

const FormSchema = z.object({
  roles: z.record(z.string(), z.coerce.boolean()),
  reason: cz.field(z.string().min(1)),
  systems: z.record(z.string(), z.coerce.boolean()),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const EditStaffMember = ({ staffId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: staff } = useStaffQuery(staffId);
  const { data: systems } = useSystemsQuery();
  const { mutateAsync: updateMember } = useUpdateStaffRolesMutation(staffId);

  const roles = Object.keys(StaffRole);

  const initialValues = {
    roles: {
      ...roles.reduce((accum: IRoles, role) => {
        accum[role] = staff?.roles.includes(role as StaffRole) ?? false;
        return accum;
      }, {}),
    },
    reason: "",
    systems:
      systems?.reduce((accum: Record<number, boolean>, system) => {
        accum[system.id] = staff?.systemIds.includes(system.id) ?? false;
        return accum;
      }, {}) ?? {},
  };

  const methods = useForm<FormType>({
    defaultValues: initialValues,
    resolver: zodResolver(FormSchema),
  });

  const values = methods.watch();

  useEffect(
    () => methods.reset(initialValues),
    [methods.reset, staff, systems],
  );

  const onSubmit = async (data: ValidatedType) => {
    const newRoles = roles.filter((role) => data.roles[role]);
    const systems = Object.entries(data.systems).reduce(
      (accum: number[], [id, value]) => {
        if (value) {
          accum.push(parseInt(id));
        }
        return accum;
      },
      [],
    );
    try {
      await updateMember({
        roles: newRoles as StaffRole[],
        reason: data.reason,
        systems,
      });
      setLocation("/staff/");
      addToast("Staff member updated", "success");
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        addToast("You cannot set your own roles.", "error");
      } else {
        addToast("Try Again", "error");
      }
    }
  };

  return (
    <>
      <Title title="Edit Staff Member Roles" />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <MultiCheckbox
                label="Roles"
                options={roles.map((role) => ({
                  label: role,
                  name: `roles.${role}`,
                }))}
              />
              <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
              <MultiCheckbox
                label="Approval Systems"
                options={
                  Object.keys(values.systems).length > 0 &&
                  systems !== undefined
                    ? systems.map((system) => ({
                        label: system.name,
                        name: `systems.${system.id}`,
                      }))
                    : []
                }
              />
              <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
              <TextField
                autoComplete="off"
                fullWidth
                label="Reason"
                name="reason"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton
                disabled={staff === undefined || systems === undefined}
                label="Edit"
              />
              <FormLinks
                links={[{ label: "Back", to: `/staff/${staffId}/` }]}
              />
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </>
  );
};

export default EditStaffMember;
