import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { UserAccessApproval, newUserAccessApproval } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useUserAccessApprovalsQuery = (reviewId: number) =>
  useQuery<UserAccessApproval[]>({
    queryKey: ["userAccessApprovals", reviewId.toString()],
    queryFn: async ({ signal }) => {
      const response = await axios.get(
        `/v1/user-access-approvals/review/${reviewId}/`,
        { signal },
      );
      return response.data.users.map((user: unknown) =>
        newUserAccessApproval(user),
      );
    },
  });

interface IApprovalData {
  approved: boolean;
  notes: string;
  roles: string[];
  serviceAccount: boolean;
  userAccessId: number;
}

export const useCreateUserAccessApprovalMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (approval: IApprovalData) =>
      await axios.post("/v1/user-access-approvals/", approval),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["userAccessApprovals"],
      });
    },
  });
};

export const useRevokeUserAccessApprovalMutation = (approvalId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await axios.delete(`/v1/user-access-approvals/${approvalId}/`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["userAccessApprovals"],
      });
    },
  });
};
