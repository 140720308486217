import {
  FormLinks,
  Page,
  ToastContext,
} from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { useLocation } from "wouter";

import { useCreateUserAccessReviewMutation, useSystemQuery } from "src/queries";

interface IProps {
  systemId: number;
}

const CreateUserAccessReview = ({ systemId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: system } = useSystemQuery(systemId);
  const { mutateAsync: create, isPending } =
    useCreateUserAccessReviewMutation(systemId);

  const onClick = async () => {
    try {
      await create();
      addToast("Review created", "success");
      setLocation(`/systems/${systemId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <Page
      breadcrumbs={[
        { label: "Systems", to: "/systems/" },
        { label: system?.name ?? "", to: `/systems/${systemId}/` },
      ]}
      disablePadding
      title="New review"
    >
      <CardContent>Create a new review for the system.</CardContent>
      <Divider />
      <CardActions>
        <LoadingButton
          color="primary"
          loading={isPending}
          onClick={onClick}
          variant="contained"
        >
          Create
        </LoadingButton>
        <FormLinks links={[{ label: "Back", to: `/systems/${systemId}/` }]} />
      </CardActions>
    </Page>
  );
};

export default CreateUserAccessReview;
