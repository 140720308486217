import { sortByKey } from "@curaleaf-international/components";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Fragment } from "react";

import { UserAccessApproval } from "src/models";
import { useRevokeUserAccessApprovalMutation } from "src/queries";

interface IProps {
  approval: UserAccessApproval;
  revokable: boolean;
}

const ApprovalItem = ({ approval, revokable }: IProps) => {
  const { mutateAsync: revoke } = useRevokeUserAccessApprovalMutation(
    approval.approvalId!,
  );

  const approvedRoles = approval
    .roles!.sort(sortByKey((role) => [role]))
    .map((role) => {
      if (!approval.initialRoles.includes(role)) {
        return (
          <Fragment key={role}>
            <b>{role}</b>,{" "}
          </Fragment>
        );
      } else {
        return <Fragment key={role}>{role}, </Fragment>;
      }
    });
  const initialRoles = approval.initialRoles
    .sort(sortByKey((role) => [role]))
    .map((role) => {
      if (!approval.roles!.includes(role)) {
        return (
          <Fragment key={role}>
            <b>{role}</b>,{" "}
          </Fragment>
        );
      } else {
        return <Fragment key={role}>{role}, </Fragment>;
      }
    });

  return (
    <>
      <ListItem
        secondaryAction={
          <>
            <Icon
              aria-label="approve"
              color={approval.approved === true ? "primary" : undefined}
              sx={{ marginRight: 2 }}
            >
              <CheckIcon />
            </Icon>
            <Icon
              aria-label="deny"
              color={approval.approved === false ? "primary" : undefined}
              sx={{ marginRight: 1 }}
            >
              <CloseIcon />
            </Icon>
            {revokable ? (
              <IconButton
                aria-label="revoke"
                onClick={() => revoke()}
                sx={{ verticalAlign: "unset" }}
              >
                <DeleteIcon />
              </IconButton>
            ) : null}
          </>
        }
        sx={{ paddingRight: 18 }}
      >
        <ListItemText
          primary={`${approval.username} ${approval.title !== null ? `(${approval.title})` : ""}`}
          secondary={
            <>
              Approved roles: {approvedRoles}
              <br />
              Initial roles: {initialRoles}
              <br />
              Approver: {approval.approver}
              {approval.serviceAccount ? (
                <>
                  <br />
                  Service account
                </>
              ) : null}
            </>
          }
        />
      </ListItem>
      <Divider variant="middle" component="li" />
    </>
  );
};

export default ApprovalItem;
