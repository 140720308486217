import {
  FormLinks,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  cz,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { ChangeRequestState } from "src/models";
import { useEditChangeRequestMutation } from "src/queries";

const FormSchema = z.object({
  reason: cz.field(z.string().min(1)),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  requestId: number;
  state: ChangeRequestState;
}

const EditChangeRequest = ({ requestId, state }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: edit } = useEditChangeRequestMutation(requestId);
  const methods = useForm<FormType>({
    defaultValues: { reason: "" },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await edit({
        reason: data.reason,
        state,
      });
      addToast("Request updated", "success");
      setLocation(`/change-requests/${requestId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  let label = "Approve";
  if (state === ChangeRequestState.DENIED) {
    label = "Deny";
  } else if (state === ChangeRequestState.WITHDRAWN) {
    label = "Withdraw";
  } else if (state === ChangeRequestState.TESTED) {
    label = "Tested";
  } else if (state === ChangeRequestState.COMPLETED) {
    label = "Complete";
  }

  return (
    <>
      <Title title="Update change request" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <TextField
                fullWidth
                helperText={`Please explain why you will ${label} this request to an auditor`}
                label="Reason for request"
                name="reason"
                autoComplete="off"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label={label} />
              <FormLinks
                links={[
                  { label: "Back", to: `/change-requests/${requestId}/` },
                ]}
              />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default EditChangeRequest;
