import {
  SelectField,
  SubmitButton,
  TextField,
  Title,
  ToastContext,
  sortByKey,
  cz,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MUITextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { AuthContext } from "src/AuthContext";
import { AccessType } from "src/models";
import { useCreateAccessRequestMutation, useSystemsQuery } from "src/queries";

const FormSchema = z.object({
  reason: cz.field(z.string().min(1)),
  roles: cz.fieldArray(z.array(z.string())),
  username: cz.field(z.string().min(1)),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

const CreateAccessRequest = () => {
  const [systemId, setSystemId] = useState("");
  const { data: systems } = useSystemsQuery();
  const system = systems?.filter(
    (system) => system.id === parseInt(systemId),
  )[0];
  const { addToast } = useContext(ToastContext);
  const { member } = useContext(AuthContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: create } = useCreateAccessRequestMutation();

  const methods = useForm<FormType, any, ValidatedType>({
    defaultValues: {
      reason: "",
      roles: [""],
      username: member!.email,
    },
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: ValidatedType) => {
    try {
      await create({
        ...data,
        systemId: system!.id,
      });
      addToast("Request received", "success");
      setLocation("/");
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <>
      <Title title="Request system access" />
      <Card>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <CardContent>
              <MUITextField
                fullWidth
                label="System"
                margin="dense"
                onChange={(event: { target: { value: string } }) => {
                  setSystemId(event.target.value);
                  const system = systems?.filter(
                    (system) => system.id === parseInt(event.target.value),
                  )[0];
                  methods.reset({
                    reason: "",
                    roles: system?.multirole ? [""] : [],
                    username: member!.email,
                  });
                }}
                select
                type="text"
                value={systemId}
              >
                {systems
                  ?.filter((system) => system.accessType === AccessType.MANUAL)
                  ?.sort(sortByKey((system) => [system.name], "asc"))
                  .map((system) => (
                    <MenuItem key={system.id} value={system.id}>
                      {system.name}
                    </MenuItem>
                  )) ?? (
                  <MenuItem key="" value="">
                    <CircularProgress size={24} />
                  </MenuItem>
                )}
              </MUITextField>
              <TextField
                fullWidth
                helperText="You can request access for someone else"
                label="Email"
                name="username"
                autoComplete="off"
                required
              />
              <SelectField
                autoComplete="off"
                disabled={system === undefined}
                fullWidth
                label="Roles"
                multiple
                name="roles"
                options={
                  system?.requestableRoles.map((role) => ({ value: role })) ??
                  []
                }
                required
              />
              <TextField
                fullWidth
                helperText="Please explain this request to an auditor"
                label="Reason for request"
                name="reason"
                autoComplete="off"
                required
              />
            </CardContent>
            <Divider />
            <CardActions>
              <SubmitButton label="Request" />
            </CardActions>
          </form>
        </FormProvider>
      </Card>
    </>
  );
};

export default CreateAccessRequest;
