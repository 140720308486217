import {
  SubmitButton,
  TextField,
  ToastContext,
  cz,
} from "@curaleaf-international/components";
import { zodResolver } from "@hookform/resolvers/zod";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import { z } from "zod";

import { useSetUserAccessListNotes, useUserAccessListQuery } from "src/queries";

const FormSchema = z.object({
  notes: cz.field(z.string()),
});
type FormType = z.input<typeof FormSchema>;
type ValidatedType = z.output<typeof FormSchema>;

interface IProps {
  listId: number;
}

const EditUserAccessListNotes = ({ listId }: IProps) => {
  const [_, setLocation] = useLocation();
  const { addToast } = useContext(ToastContext);
  const { data: list } = useUserAccessListQuery(listId);
  const { mutateAsync: setNotes } = useSetUserAccessListNotes(listId);

  const defaultValues = {
    notes: list?.notes ?? "",
  };

  const methods = useForm<FormType>({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  useEffect(() => methods.reset(defaultValues), [list]);

  const onSubmit = async (data: ValidatedType) => {
    try {
      await setNotes(data.notes.trim());
      addToast("List updated", "success");
      setLocation(`/user-access-lists/${listId}/`);
    } catch {
      addToast("Try again", "error");
    }
  };

  return (
    <Card>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CardContent>
            <TextField
              fullWidth
              label="Notes"
              multiline
              name="notes"
              autoComplete="off"
              required
            />
          </CardContent>
          <Divider />
          <CardActions>
            <SubmitButton label="Set notes" />
          </CardActions>
        </form>
      </FormProvider>
    </Card>
  );
};

export default EditUserAccessListNotes;
